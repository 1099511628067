import React from "react";
import PropTypes from "prop-types";

const Image = ({ className, src, width, height, alt, ...props }) => {
  return (
    <img
      {...{ src, width, height, alt }}
      className={className}
      src={src}
      width={width}
      height={height}
      alt={alt}
      style={props.style}
    />
  );
};

Image.propTypes = {
  src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string,
  small: PropTypes.bool,
};
Image.defaultProps = {
  src: undefined,
  width: undefined,
  height: undefined,
  alt: undefined,
};

export default Image;
