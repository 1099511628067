import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  border-radius: 60px;
  overflow: hidden;
  box-shadow: 0px 30px 100px rgba(0, 0, 0, 0.9);
`;

const Screenshot = props => {
  return (
    <Wrapper className="inline-flex relative justify-center items-center">
      {/* Image inside mockup size: 290x624px (or 580x1248px for Retina devices) */}
      <img className="absolute" src={props.src} width="310" style={{ maxWidth: '90%', top: 15 }} alt="App screen 04" />
      {/* iPhone mockup */}
      <img
        className="relative max-w-full mx-auto h-auto pointer-events-none"
        src={require('../images/iphone-mockup.png').default}
        width="344"
        height="674"
        alt="iPhone mockup"
        aria-hidden="true"
      />
    </Wrapper>
  );
};

export default Screenshot;
