import React from 'react';

function FeaturesBlocks() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-200 dark:border-gray-800">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 font-red-hat-display">Secure and Private</h2>
          </div>

          {/* Items */}
          <div
            className="max-w-xs mx-auto sm:max-w-none md:max-w-xl lg:max-w-none grid sm:grid-cols-2 gap-4 lg:gap-6 lg:grid-cols-4"
            data-aos-id-featbl
          >
            <a
              className="flex flex-col p-5 group text-white bg-gradient-to-tr from-pink-500 to-pink-400 dark:to-pink-500 shadow-2xl rounded-2xl"
              href="#0"
              data-aos="fade-down"
              data-aos-anchor="[data-aos-id-featbl]"
              data-aos-delay="300"
            >
              <span className="iconify text-2xl mb-1" data-icon="entypo:price-tag" data-inline="false"></span>
              <div className="font-red-hat-display text-xl font-extrabold tracking-tighter mb-1">Limit Permissions</div>
              <div className="flex-grow opacity-80 mb-4">
                Wally will never ask for anything else than your public Wallet ID
              </div>
            </a>
            <a
              className="flex flex-col p-5 group text-white bg-gradient-to-tr from-teal-500 to-teal-400 dark:to-teal-500 shadow-2xl rounded-2xl"
              href="#0"
              data-aos="fade-down"
              data-aos-anchor="[data-aos-id-featbl]"
            >
              <span className="iconify text-2xl mb-1" data-icon="entypo:price-tag" data-inline="false"></span>
              <div className="font-red-hat-display text-xl font-extrabold tracking-tighter mb-1">Public Data</div>
              <div className="flex-grow opacity-80 mb-4">
                Token balances are pulled from the different blockchains and prices from CoinGecko
              </div>
            </a>

            {/* 2nd item */}
            <a
              className="flex flex-col p-5 group text-white bg-gradient-to-tr from-purple-500 to-purple-400 dark:to-purple-500 shadow-2xl rounded-2xl"
              href="#0"
              data-aos="fade-down"
              data-aos-anchor="[data-aos-id-featbl]"
              data-aos-delay="100"
            >
              <span className="iconify text-2xl mb-1" data-icon="entypo:price-tag" data-inline="false"></span>
              <div className="font-red-hat-display text-xl font-extrabold tracking-tighter mb-1">No Transactions</div>
              <div className="flex-grow opacity-80 mb-4">
                Wally is a data visualization tool and is not able to make transactions
              </div>
            </a>

            {/* 3rd item */}
            <a
              className="flex flex-col p-5 group text-white bg-gradient-to-tr from-indigo-500 to-indigo-400 dark:to-indigo-500 shadow-2xl rounded-2xl"
              href="#0"
              data-aos="fade-down"
              data-aos-anchor="[data-aos-id-featbl]"
              data-aos-delay="200"
            >
              <span className="iconify text-2xl mb-1" data-icon="entypo:price-tag" data-inline="false"></span>
              <div className="font-red-hat-display text-xl font-extrabold tracking-tighter mb-1">
                Your Data is Yours
              </div>
              <div className="flex-grow opacity-80 mb-4">
                Your data and custom settings are not exposed or sent to any external server and stay in your device
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;
