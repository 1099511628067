import React from 'react';
import Image from '../elements/Image';

const imageStyle = {
  boxShadow: '0px 30px 100px rgba(0, 0, 0, 0.9)',
  borderLeft: '4px solid #888',
  borderRight: '4px solid #888',
};

const FeaturesSplit = () => {
  return (
    <section id="features">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-200 dark:border-gray-800">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 font-red-hat-display">Useful and Fun</h2>
          </div>

          {/* Items */}
          <div className="md:grid md:grid-cols-12 md:gap-12 mb-20">
            <div className="md:col-span-6 lg:col-span-6 p-10 justify-center">
              <h1 className="h3 mb-4 font-red-hat-display font-extrabold">Multiple Networks</h1>
              <p className="text-xl text-gray-600 dark:text-gray-400" data-aos="fade-down" data-aos-delay="150">
                No need to go back and forth to see your balances in different networks. <b>Wally</b> supports Ethereum,
                Binance Smart Chain, Polygon/MATIC and Fantom and aggregates pricing in one single view.
              </p>
            </div>
            <div className="md:col-span-6 lg:col-span-6 p-10 justify-center">
              <Image src={require('../images/networks.png').default} style={imageStyle} />
            </div>
          </div>

          <div className="md:grid md:grid-cols-12 md:gap-12 mb-20">
            <div className="md:col-span-6 lg:col-span-6 p-10 justify-center">
              <Image src={require('../images/moon.png').default} style={imageStyle} />
            </div>
            <div className="md:col-span-6 lg:col-span-6 p-10 justify-center">
              <h1 className="h3 mb-4 font-red-hat-display font-extrabold">Moontipliers</h1>
              <p className="text-xl text-gray-600 dark:text-gray-400" data-aos="fade-down" data-aos-delay="150">
                Ever wonder what would happen if a token "mooned"? Use Moontipliers to test different scenarios and plan
                your moon trip!
              </p>
            </div>
          </div>

          <div className="md:grid md:grid-cols-12 md:gap-12 mb-20">
            <div className="md:col-span-6 lg:col-span-6 p-10 justify-center">
              <h1 className="h3 mb-4 font-red-hat-display font-extrabold">Convenient</h1>
              <p className="text-xl text-gray-600 dark:text-gray-400" data-aos="fade-down" data-aos-delay="150">
                Have you received random airdrops from unwanted tokens? Just hide them so they don't pollute your
                wallet. We are also building a central database of scam tokens so you don't have to waste your time.
              </p>
            </div>
            <div className="md:col-span-6 lg:col-span-6 p-10 justify-center">
              <Image src={require('../images/hidden.png').default} style={imageStyle} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSplit;
